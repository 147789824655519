import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { ADMIN_GR_HISTORY_LOGS } from 'Consts/routes';

import { withVariables } from 'Utils/string';

import StyledComponent from 'Components/core/StyledComponent';
import PageContent from 'Components/layout/panel/PageContent';
import TabNavigation from 'Components/layout/panel/TabNavigation';
import List from 'Components/admin/grHistoryLogs/List';

export const TAB_LIST = 'list';

export default class AdminGrHistoryLogs extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
    };
    static defaultProps = {};

    defaultQuery = {
        tab: null,
    };

    render() {
        const { location, history } = this.props;

        return (
            <StyledComponent
                className="page-gr-history-logs"
                styles={require('./styles')}
            >
                <PageContent
                    breadcrumbs={[{
                        to: withVariables(ADMIN_GR_HISTORY_LOGS.path),
                        label: 'Historia getresponse',
                    }]}
                >
                    <TabNavigation
                        location={location}
                        history={history}
                        headline="Historia getresponse"
                        tabs={[{
                            key: TAB_LIST,
                            label: 'Lokacje',
                            children: (
                                <List
                                    location={location}
                                    history={history}
                                />
                            ),
                        }]}
                    />  
                </PageContent>
            </StyledComponent>
        );
    }
}
