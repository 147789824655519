import { 
    GR_HISTORY_LOGS_CATEGORIES,
    GR_HISTORY_LOGS_CATEGORY_CUSTOM_FIELDS,
    GR_HISTORY_LOGS_CATEGORY_EVENT,
} from 'Consts/grHistoryLogs';

export const getCategoryLabel = grHistoryLog => {
    if(!grHistoryLog) {
        return { found: false, label: 'Nieznana' };
    }

    const { category } = grHistoryLog;
    const option = GR_HISTORY_LOGS_CATEGORIES.find(option => option.value === category);
    if (!option) {
        return { found: false, label: 'Nieznana' };
    }

    return { found: true, label: option.label };
};

export const getAdditionals = grHistoryLog => {
    if(grHistoryLog.category === GR_HISTORY_LOGS_CATEGORY_CUSTOM_FIELDS) {
        return [];
    }

    if(grHistoryLog.category === GR_HISTORY_LOGS_CATEGORY_EVENT) {
        return [];
    }

    return [];
};