import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactJson from 'react-json-view';

import StyledComponent from 'Components/core/StyledComponent';

export default class PanelGrHistoryLogsDetails extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        data: PropTypes.object,
        isOpen: PropTypes.bool,
        onClose: PropTypes.func,
    };
    static defaultProps = {
        data: null,
        isOpen: false,
        onClose: null,
    };

    render() {
        const { data } = this.props;

        return (
            <StyledComponent
                className="panel-gr-history-logs-details"
                styles={require('./styles')}
            >
                <div className="cols">
                    <div className="col">
                        <ReactJson 
                            src={data.payload && JSON.parse(data.payload) || { data: null }} 
                            name="Payload"
                        />
                    </div>
                    <div className="col">
                        <ReactJson 
                            src={data.response && JSON.parse(data.response) || { data: null }} 
                            name="Response"
                        />
                    </div>
                </div>
            </StyledComponent>
        );
    }
}