import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { parseQueryToObject } from 'Utils/querystring';
import { filterKeys } from 'Utils/object';
import { getFormattedDate } from 'Utils/date';
import { getCategoryLabel, getAdditionals } from 'Utils/grHistoryLog';
import { getFullName } from 'Utils/user';

import StyledComponent from 'Components/core/StyledComponent';
import PaginatedList from 'Components/layoutAdmin/panel/PaginatedList';
import PaginatedListElement from 'Components/layoutAdmin/panel/PaginatedListElement';
import ModalDetails from 'Components/admin/grHistoryLogs/ModalDetails';

export default class PanelGrHistoryLogsList extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            list: PropTypes.func.isRequired,
        }).isRequired,
        grHistoryLogs: PropTypes.object.isRequired,
        predefinedQuery: PropTypes.object,
        onMapControls: PropTypes.func,
        onMapAdditionals: PropTypes.func,
        onMapFilters: PropTypes.func,
        onMapLabels: PropTypes.func,
        onMapTitle: PropTypes.func,
        onMapSubTitle: PropTypes.func,
    };
    static defaultProps = {
        predefinedQuery: {},
        onMapControls: (elem, value) => value,
        onMapAdditionals: (elem, value) => value,
        onMapFilters: value => value,
        onMapLabels: (elem, value) => value,
        onMapTitle: value => value,
        onMapSubTitle: value => value,
    };
    state = {
        currentDetails: null,
    }

    defaultQuery = {
        page: 1,
        userId: undefined,
        category: undefined,
        timestamp: undefined,
        ...(this.props.predefinedQuery || {}), //eslint-disable-line react/destructuring-assignment
    };

    getQueryConfig = (props = this.props) => {
        const { location, predefinedQuery } = props;
        const { search } = location;
        const queryObject = parseQueryToObject(search, true);

        return filterKeys(
            this.defaultQuery,
            { ...queryObject, ...predefinedQuery },
            Object.keys(this.defaultQuery)
        );
    }

    componentDidMount = () => {
        this.loadData();
    }

    componentDidUpdate = prevProps => {
        const { predefinedQuery } = this.props;
        const previousQueryObject = this.getQueryConfig(prevProps);
        const queryObject = this.getQueryConfig();

        if (JSON.stringify(previousQueryObject) !== JSON.stringify(queryObject)) {
            this.loadData();
        }

        if (JSON.stringify(prevProps.predefinedQuery) !== JSON.stringify(predefinedQuery)) {
            this.loadData();
        }
    }

    loadData = () => {
        const { actions } = this.props;
        const queryObject = this.getQueryConfig();

        clearTimeout(this.debounceTimeout);
        this.debounceTimeout = setTimeout(() => {
            actions.list({ ...queryObject });
        }, 500);
    }

    render() {
        const {
            grHistoryLogs,
            location,
            history,
            onMapAdditionals,
            onMapLabels,
            onMapTitle,
            onMapSubTitle,
        } = this.props;
        const { currentDetails } = this.state;

        return (
            <StyledComponent
                className="panel-gr-history-logs-list"
                styles={require('./styles')}
            >
                <PaginatedList
                    location={location}
                    history={history}
                    collection={grHistoryLogs}
                    title="Historia getresponse"
                    queryConfig={this.getQueryConfig()}
                    onMapElement={element => (
                        <PaginatedListElement
                            key={element.id}
                            title={onMapTitle(getFormattedDate(element.createdAt))}
                            subtitle={onMapSubTitle(element.user && getFullName(element.user).label || 'Nieznany')}
                            additionals={onMapAdditionals(element, getAdditionals(element))}
                            labels={onMapLabels(element, [{
                                isVisible: true,
                                label: element.success
                                    ? 'Ok'
                                    : 'Błąd',
                                state: element.success
                                    ? 'success'
                                    : 'alert',
                            }, {
                                isVisible: true,
                                label: getCategoryLabel(element).label,
                                state: 'success',
                            }])}
                            controls={[{
                                type: 'button',
                                label: 'Szczegóły',
                                visible: true,
                                onClick: () => this.setState({ currentDetails: element }),
                            }]}
                        />
                    )}
                />

                {currentDetails && (
                    <ModalDetails
                        location={location}
                        history={history}
                        isOpen={true}
                        onClose={() => this.setState({ currentDetails: null })}
                        data={currentDetails}
                    />
                )}
            </StyledComponent>
        );
    }
}
