import { withVariables } from 'Utils/string';
import { reduxRequest as request } from 'Services/Api';

import * as types from './types';
import * as endpoints from 'Consts/api';

export const list = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.LIST,
        method: 'GET',
        path: endpoints.ENDPOINT_ADMIN_GR_HISTORY_LOGS,
        params,
        requestParams: {
            page: params.page || 1,
            perPage: params.perPage || 10,
            userId: params.userId || undefined,
            category: params.category || undefined,

        },
    }));
};

export const single = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.SINGLE,
        method: 'GET',
        path: withVariables(endpoints.ENDPOINT_ADMIN_GR_HISTORY_LOG, params),
        params,
    }));
};
